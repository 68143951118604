import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
// assets
import { ICO_LION /*, ICO_LINKEDIN*/ } from '../../img'

const useStyles = makeStyles(() => ({
  root: {
    background: '#FFF',
    paddingLeft: '200px',
    paddingTop: '200px',
    maxWidth: '1920px',
  },
  footer: {
    alignItems: 'flex-start',
    background: '#FFF',
    borderTop: 'solid 2px #1D1D1B !important',
    display: 'flex',
    justifyContent: 'space-between',
    whiteSpace: 'pre-line',
    wordWrap: 'break-word',
    padding: '20px 0px 100px 0px',
    maxWidth: '1520px',
    width: '100%',
    font: '20px Roboto !important',
  },
  title: {
    font: '40px RobotoSlab !important',
  },
  textfield: {
    color: 'white !important',
  },
  link: {
    color: '#1D1D1B',
    width: '300px',
    textDecoration: 'none',
    paddingBottom: '20px !important',
  },
}))

export const Footer = props => {
  const classes = useStyles()
  const { data } = props

  return (
    <div className={classes.root}>
      <div className={classes.footer}>
        <span>
          <img src={ICO_LION} alt={''} /> &copy; 2021 Württembergische Energie
        </span>
        <span>{data.title}</span>
        <span>
          <Link className={classes.link} to={'/impressum'}>
            Impressum
          </Link>
          <br />
          <br />
          <Link className={classes.link} to={'/datenschutz'}>
            Datenschutz
          </Link>
        </span>
        <span>
          {/*<a
            href={'https://www.xing.com/pages/wolff-mullerenergygmbh'}
            rel={'noreferrer'}
            target={'_blank'}
          >
            <img src={ICO_XING} alt={''} />
          </a>*/}
          {/*<a
            href={
              'https://de.linkedin.com/company/w%C3%BCrttembergische-energie-gmbh'
            }
            rel={'noreferrer'}
            target={'_blank'}
          >
            <img src={ICO_LINKEDIN} alt={''} />
          </a>*/}
        </span>
      </div>
    </div>
  )
}

Footer.propTypes = {
  data: PropTypes.object,
}
