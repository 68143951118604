import {
  Button,
  Checkbox,
  Collapse,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { tableCellClasses } from '@mui/material/TableCell'
import { makeStyles } from '@mui/styles'
import PropTypes from 'prop-types'
import React, { useContext, useState } from 'react'
// context
import FormData from '../../context/formData.context'
import PriceConditions from '../../context/priceConditions.context'
// assets
import { ICO_CHECKBOX } from '../../img'
import { isValidLength, isValidEmail } from '../../api/util'

const useStyles = makeStyles(() => ({
  root: {
    background: '#FFF',
    paddingBottom: '100px',
  },
  title: {
    font: '40px RobotoSlab !important',
    marginTop: '50px !important',
  },
  columnName: {
    textTransform: 'uppercase',
    width: '200px',
    font: '14px RobotoBlack !important',
    padding: '0 !important',
    letterSpacing: '1px',
  },
  sub: {
    width: '200px',
    fontSize: '14px',
    padding: '0 !important',
  },
  columnHeader: {
    width: '200px',
    font: '60px RobotoSlab !important',
    padding: '0 !important',
  },
  section: {
    background: '#F4F2F2',
    marginBottom: '20px',
    padding: '20px',
  },
  details: {
    marginTop: '50px !important',
    backgroundColor: 'transparent !important',
    border: 'solid 1px #707070 !important',
    width: '300px',
  },
  request: {
    marginTop: '50px !important',
    marginLeft: '20px !important',
    backgroundColor: '#A00523 !important',
    border: 'solid 1px #A00523 !important',
    color: 'white !important',
    width: '100%',
  },
  table: {
    _width: '400px',
    _maxWidth: '400px',
    overflow: 'hidden',
  },
  dialogTitle: {
    background: '#A00523',
    color: 'white',
  },
  requestOffer: {
    background: '#a00523 !important',
    color: 'white !important',
    width: '300px',
  },
  requestOffer_disabled: { background: '#CCCCCC !important', width: '300px' },
}))

const OfferTable = props => {
  const {
    amount,
    branch,
    fee,
    medium,
    price,
    runtime,
    startDate,
    taxes,
  } = props

  const classes = useStyles()
  const priceData = useContext(PriceConditions)

  const userLocales = 'de-DE'
  const precision = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }

  const { REACT_APP_BASE_URL: BASE_URL } = process.env

  const [detailed, setDetailed] = useState(false)
  const [showRequestOffer, setShowRequestOffer] = useState(false)
  const [offerPrice, setOfferPrice] = useState(0)

  const [gender, setGender] = useState('')
  const [name, setName] = useState('')
  const [company, setCompany] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [privacyAck, setPrivacyAck] = useState(false)

  const [preflight, setPreflight] = useState(false)

  const getGasPrice = (amount, runtime) => {
    let price = 0
    let index

    if (runtime === 12) index = 0
    if (runtime === 24) index = 1
    if (runtime === 36) index = 2

    const { factors } = priceData.gasData[index]

    if (amount >= 1200000) {
      price = factors[3].value
    } else if (amount >= 800000 && amount < 1200000) {
      price = factors[2].value
    } else if (amount >= 300000 && amount < 800000) {
      price = factors[1].value
    } else if (amount >= 100000 && amount < 300000) {
      price = factors[0].value
    }

    return price
  }

  return (
    <TableContainer>
      <Table
        className={classes.table}
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: 'none',
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell className={classes.columnName} align={'right'}>
              Laufzeit
            </TableCell>
            <TableCell className={classes.columnName} align={'right'}>
              Energiepreis
            </TableCell>
            <TableCell className={classes.columnName} align={'right'}>
              Gesamtpreis
            </TableCell>
          </TableRow>
        </TableHead>

        <Dialog
          onClose={() => setShowRequestOffer(false)}
          open={showRequestOffer}
        >
          <DialogTitle className={classes.dialogTitle}>
            Angebot anfordern ({runtime} Monate)
          </DialogTitle>

          <DialogContent>
            <form>
              <Container maxWidth={'md'}>
                <FormControl fullWidth className={classes.title}>
                  <InputLabel>Anrede</InputLabel>
                  <Select
                    label={'Anrede'}
                    onChange={e => setGender(e.target.value)}
                    required
                    value={gender}
                    variant={'standard'}
                  >
                    <MenuItem value={''}>Bitte wählen</MenuItem>
                    <MenuItem value={'Herr'}>Herr</MenuItem>
                    <MenuItem value={'Frau'}>Frau</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth>
                  <TextField
                    className={classes.title}
                    error={!isValidLength(name, 2) && preflight}
                    label={'Vor- und Nachname'}
                    onChange={e => setName(e.target.value)}
                    required
                    type={'text'}
                    value={name}
                    variant={'standard'}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <TextField
                    className={classes.title}
                    error={!isValidLength(company, 2) && preflight}
                    label={'Firma'}
                    onChange={e => setCompany(e.target.value)}
                    required
                    type={'text'}
                    value={company}
                    variant={'standard'}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <TextField
                    className={classes.title}
                    label={'Telefon'}
                    onChange={e => setPhone(e.target.value)}
                    type={'tel'}
                    value={phone}
                    variant={'standard'}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <TextField
                    className={classes.title}
                    error={!isValidEmail(email) && preflight}
                    label={'E-Mail'}
                    onChange={e => setEmail(e.target.value)}
                    required
                    type={'email'}
                    value={email}
                    variant={'standard'}
                  />
                </FormControl>

                <FormControl fullWidth>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checkedIcon={<CheckCircleIcon />}
                        icon={<img alt={''} src={ICO_CHECKBOX} />}
                        checked={privacyAck}
                        onClick={() => {
                          setPrivacyAck(!privacyAck)
                          setPreflight(!preflight)
                        }}
                      />
                    }
                    label={
                      'Ich akzeptiere die Datenschutzbestimmungen des Anbieters'
                    }
                  />
                </FormControl>
              </Container>
            </form>
          </DialogContent>

          <DialogActions>
            <Button
              // google tracker
              //class={`google_tracker__gas_${runtime}`}
              class={`google_tracker__gas`}
              // google tracker
              className={
                privacyAck
                  ? classes.requestOffer
                  : classes.requestOffer_disabled
              }
              disabled={
                !privacyAck ||
                !isValidEmail(email) ||
                !isValidLength(name, 2) ||
                !isValidLength(company, 2)
              }
              onClick={() => {
                setPreflight(true)

                console.log(
                  startDate,
                  name,
                  email,
                  company,
                  phone,
                  gender,
                  medium,
                  branch,
                  runtime,
                  amount,
                  offerPrice
                )

                fetch(`${BASE_URL}/request-offer`, {
                  method: 'POST',
                  headers: {
                    accept: 'application/json, text/plain, */*',
                    'Content-Type': 'application/json',
                  },
                  body: `{"startDate": "${startDate}", "name": "${name}", "email": "${email}", "company": "${company}", "phone": "${phone}", "gender": "${gender}", "medium": "${medium}", "branch": "${branch}", "runtime": "${runtime}", "amount": "${amount}", "offerPrice": "${offerPrice}"}`,
                })
                  .then(() => setShowRequestOffer(false))
                  .catch(err => {
                    console.error('error', err)
                    setShowRequestOffer(false)
                  })
              }}
            >
              Jetzt anfordern
            </Button>
          </DialogActions>
        </Dialog>

        <TableBody>
          <TableRow>
            <TableCell className={classes.sub} align={'right'}>
              &nbsp;
            </TableCell>
            <TableCell className={classes.sub} align={'right'}>
              zzgl. Netzentgelte, Abgaben und Steuern
            </TableCell>
            <TableCell className={classes.sub} align={'right'}>
              zzgl. Netzentgelte, Abgaben und Steuern
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.columnHeader} align={'right'}>
              {runtime}
            </TableCell>
            <TableCell className={classes.columnHeader} align={'right'}>
              {getGasPrice(amount, runtime)
                .toLocaleString(userLocales, precision)
                .replace('.', ',')}
            </TableCell>
            <TableCell className={classes.columnHeader} align={'right'}>
              {Math.round(
                (getGasPrice(amount, runtime) * amount) / 100
              ).toLocaleString(userLocales)}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.sub} align={'right'}>
              Monate
            </TableCell>
            <TableCell className={classes.sub} align={'right'}>
              ct./kWh
            </TableCell>
            <TableCell className={classes.sub} align={'right'}>
              EUR
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell colSpan={3}>
              <Collapse in={detailed}>
                <Table>
                  <TableBody>
                    <TableRow style={{ borderTop: 'solid 1px #707070' }}>
                      <TableCell className={classes.columnName} align={'right'}>
                        Netzentgelt
                      </TableCell>
                      <TableCell
                        className={classes.columnHeader}
                        align={'right'}
                      >
                        {Number(fee).toLocaleString(userLocales, precision)}{' '}
                        <sup style={{ fontSize: 12 }}>1)</sup>
                      </TableCell>
                      <TableCell
                        className={classes.columnHeader}
                        align={'right'}
                      >
                        {Math.round((amount * fee) / 100).toLocaleString(
                          userLocales
                        )}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align={'right'}>&nbsp;</TableCell>
                      <TableCell align={'right'}>ct./kWh</TableCell>
                      <TableCell align={'right'}>EUR</TableCell>
                    </TableRow>

                    <TableRow style={{ borderTop: 'solid 1px #707070' }}>
                      <TableCell className={classes.columnName} align={'right'}>
                        Abgaben & Steuern
                      </TableCell>
                      <TableCell
                        className={classes.columnHeader}
                        align={'right'}
                      >
                        {Number(taxes).toLocaleString(userLocales, precision)}{' '}
                        <sup style={{ fontSize: 12 }}>2)</sup>
                      </TableCell>
                      <TableCell
                        className={classes.columnHeader}
                        align={'right'}
                      >
                        {Math.round((amount * taxes) / 100).toLocaleString(
                          userLocales
                        )}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align={'right'}>&nbsp;</TableCell>
                      <TableCell align={'right'}>ct./kWh</TableCell>
                      <TableCell align={'right'}>EUR</TableCell>
                    </TableRow>

                    <TableRow style={{ borderTop: 'solid 1px #707070' }}>
                      <TableCell className={classes.columnName} align={'right'}>
                        Gesamt
                      </TableCell>
                      <TableCell
                        className={classes.columnHeader}
                        align={'right'}
                      >
                        {(
                          Math.round(
                            (getGasPrice(amount, runtime) + fee + taxes) * 100
                          ) / 100
                        )
                          .toLocaleString(userLocales, precision)
                          .replace('.', ',')}
                      </TableCell>
                      <TableCell
                        className={classes.columnHeader}
                        align={'right'}
                      >
                        {Math.round(
                          (amount *
                            (fee + taxes + getGasPrice(amount, runtime))) /
                            100
                        ).toLocaleString(userLocales)}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell align={'right'}>&nbsp;</TableCell>
                      <TableCell align={'right'}>ct./kWh</TableCell>
                      <TableCell align={'right'}>EUR</TableCell>
                    </TableRow>

                    <TableRow style={{ borderTop: 'solid 1px #707070' }}>
                      <TableCell colSpan={3}>
                        <small>
                          1) Durchschnittliches Netzentgelt einer
                          SLP-Abnahmestelle (Stand 01/2022, Abhängig von
                          Netzbetreiber und Verbrauch)
                          <br />
                          2) Durchschnittliche Abgaben und Steuern einer
                          SLP-Abnahmestelle (Stand 01/2022, Abhängig vom
                          Verbrauch)
                        </small>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Collapse>
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell className={classes.sub} align={'right'} />
            {/* Details anzeigen */}
            <TableCell className={classes.sub} align={'right'}>
              <Button
                className={classes.details}
                onClick={() => setDetailed(!detailed)}
              >
                Details {!detailed ? 'anzeigen' : 'ausblenden'}
              </Button>
            </TableCell>
            {/* Angebot anfordern */}
            <TableCell className={classes.sub} align={'right'}>
              <Button
                className={classes.request}
                onClick={() => {
                  console.log(price)
                  setOfferPrice(
                    (Math.ceil(price * 1000) / 1000)
                      .toLocaleString(userLocales)
                      .replace('.', ',')
                  )
                  setShowRequestOffer(true)
                }}
              >
                Angebot anfordern
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

OfferTable.propTypes = {
  price: PropTypes.number,
  amount: PropTypes.any,
  branch: PropTypes.any,
  medium: PropTypes.string,
  runtime: PropTypes.number,
  fee: PropTypes.number,
  taxes: PropTypes.number,
  startDate: PropTypes.any,
}

export const GasOffers = () => {
  const classes = useStyles()
  const { formData } = useContext(FormData)
  const priceData = useContext(PriceConditions)
  const { gas_network_fee: fee, gas_charges_taxes: taxes } = priceData

  return (
    <div className={classes.root}>
      <Container>
        <Typography className={classes.title}>Ihre Gasangebote</Typography>

        <div className={classes.section}>
          <OfferTable
            amount={formData.amount}
            branch={formData.branch}
            medium={formData.medium}
            price={formData.finalPrices[0]}
            startDate={formData.startDate}
            runtime={12}
            fee={fee}
            taxes={taxes}
          />
        </div>

        <div className={classes.section}>
          <OfferTable
            amount={formData.amount}
            branch={formData.branch}
            medium={formData.medium}
            price={formData.finalPrices[1]}
            startDate={formData.startDate}
            runtime={24}
            fee={fee}
            taxes={taxes}
          />
        </div>

        <div className={classes.section}>
          <OfferTable
            amount={formData.amount}
            branch={formData.branch}
            medium={formData.medium}
            price={formData.finalPrices[2]}
            startDate={formData.startDate}
            runtime={36}
            fee={fee}
            taxes={taxes}
          />
        </div>
      </Container>
    </div>
  )
}
